export const date = {
  januaryGenitiveCase: 'січня',
  februaryGenitiveCase: 'лютого',
  marchGenitiveCase: 'березня',
  aprilGenitiveCase: 'квітня',
  mayGenitiveCase: 'травня',
  juneGenitiveCase: 'червня',
  julyGenitiveCase: 'липня',
  augustGenitiveCase: 'серпня',
  septemberGenitiveCase: 'вересня',
  octoberGenitiveCase: 'жовтня',
  novemberGenitiveCase: 'листопада',
  decemberGenitiveCase: 'грудня',
};
