export const editor = {
  videoCreation: 'Створення відео',
  articleCreation: 'Створення статті',
  noteCreation: 'Створення допису',
  videoUpdation: 'Редагування відео',
  articleUpdation: 'Редагування статті',
  noteUpdation: 'Редагування допису',
  cancelCreation: 'Відмінити створення',
  cancelUpdation: 'Відмінити редагування',
  backToUpdation: 'Назад до редагування',
  wantToCancel: 'Ви дійсно бажаєте відмінити',
  preview: 'Попередній перегляд',
  publish: 'Опублікувати',
  save: 'Зберегти',
  sendToReview: 'Відправити на модерацію',
  creation: 'Створення',
  updation: 'Редагування',
  videoTitle: 'Заголовок відео',
  articleTitle: 'Заголовок статті',
  noteTitle: 'Заголовок допису',
  articleText: 'Текст статті',
  noteText: 'Текст допису',
  videoDescription: 'Опис відео',
  enterTitleForVideo: 'Введіть заголовок до фото',
  enterUrlForImage: 'Введіть посилання на зображення',
  enterUrlForVideo: 'Введіть посилання на відео',
  enterCorrectUrl: 'Введіть коректну URL-адресу',
  fillFieldWithUrl: 'Заповніть поле з посиланням',
  addImage: 'Додати зображення',
  backgroundImage: 'Фонове зображення',
  warn: '(тільки для перекладу або медитеки)',
  carouselImage: 'Зображення для каруселі',
  byComputer: "З комп'ютера",
  byUrl: 'За посиланням',
  videoFile: 'Відеофайл',
  title: 'Заголовок',
  introduction: 'Вступ',
  note: 'Примітка',
  plain: 'Звичайний',
  materialCardText: 'Текст картки матеріалу',
  maxTextlength: 'Максимальна довжина тексту: {{count}} символів',
  textLength:
    'Мінімальна довжина 50 символів. Довжина тексту: {{count}} символ',
  textLength_0:
    'Мінімальна довжина 50 символів. Довжина тексту: {{count}} символ',
  textLength_1:
    'Мінімальна довжина 50 символів. Довжина тексту: {{count}} символи',
  textLength_2:
    'Мінімальна довжина 50 символів. Довжина тексту: {{count}} символів',
  confirmImgRemoving: 'Ви впевнені, що хочете видалити цю картинку?',
  removingSucceeded: 'Успішно видалено!',
  addImgFromPC: 'Завантажити зображення із комп`ютера',
  addImgForPC: 'Зображення для компʼютера',
  addImgForMobile: 'Зображення для телефона',
  addImgFromExternalResource:
    'Додати посилання на зображення із зовнішнього ресурсу',
  requiredField: 'Заповніть, будь-ласка, обов`язкові поля, помічені зірочкою',
  notEnoughLength: 'Ви ввели недостатньо символів',
  notUASymbols: 'Введіть текст українською мовою',
  noVideo: 'Додайте, будь ласка, відео',
  toMuchTitleLength: 'Ви ввели забагато символів',
  noBgImg: `Фонове зображення обов'язкове`,
  pcImageSize: `(Рекомендоване розширення зображення - 1305x455)`,
  mobileImageSize: `(Рекомендоване розширення зображення - 414x585)`,
  allSizesWarning: `Для візуалізації зображень статті в каруселі потрібно завантажити усі варіації зображень картинки для статті (‘Зображення для компʼютера’ та ‘Зображення для телефона’)`,
};
