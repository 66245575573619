export const loginRegistration = {
  enterEmailAndPassword: 'Введіть E-mail і пароль',
  wrongEmailOrPassword: 'Ви ввели неправильний логін та/або пароль!',
  rememberMe: "Запам'ятати мене",
  dontHaveAccount: 'Не маєте облікового запису',
  registerNow: 'Зареєструйтеся зараз',
  register: 'Зареєструватися',
  logIn: 'Увійти',
  firstName: "Ім'я",
  lastName: 'Прізвище',
  password: 'Пароль',
  recuired: "Це поле є обов'язковим",
  orLogInWith: 'Або увійдіть за допомогою',
  passwordsNotMatch: 'Паролі не збігаються',
  repeatPassword: 'Повторіть пароль',
  incorrectEmail: 'Неправильний формат email',
  minLenForPassword: 'Пароль повинен містити щонайменше 8 символів',
  maxLenForPassword: 'Пароль повинен містити щонайбільше 24 символи',
  maxLenForEmail: 'E-mail повинен містити щонайбільше 40 символів',
  enterInfoAboutYourself: 'Введіть інформацію про себе',
  congratulation: 'Вітаємо!',
  youAreWelcome: 'Ви увійшли',
  forgotPassword: 'Забули пароль?',
};
