export const admin = {
  id: 'ID',
  title: 'Заголовок',
  status: 'Статус',
  dateOfStatusChange: 'Дата зміни статусу',
  direction: 'Тема',
  author: 'Автор',
  realViews: 'Реальна кількість переглядів',
  views: 'Кількість переглядів, що відображається',
  viewsFull: 'Кількість переглядів, що відображається',
  realViewsFull: 'Реальна кількість переглядів',
  actions: 'Дії',
  DRAFT: 'Чернетка',
  NEEDS_EDITING: 'Потребує редагування',
  MODERATION_FIRST_SIGN: 'Не переглянутий',
  MODERATION_SECOND_SIGN: 'На модерації',
  PLANNED: 'Заплановано',
  PUBLISHED: 'Опублікований',
  ARCHIVED: 'Архівований',
  edit: 'Редагувати',
  delete: 'Видалити',
  archive: 'Надіслати в архів',
  remove: 'Видалити',
  publish: 'Опублікувати',
  schedulePublish: 'Запланувати публікацію',
  changePublicationDate: 'Змінити дату публікації',
  changeViewsCount: 'Змінити кількість переглядів',
  currentPublicationDate: 'Поточна дата публікації',
  newPublicationDate: 'Нова дата публікації',
  choosePublicationDate: 'Обрати дату, час',
  returnToAuthor: 'Надіслати автору на редагування',
  statuses: 'Статуси',
  directions: 'Теми',
  types: 'Типи',
  returnToAuthorTitle:
    'Ви впевнені, що хочете повернути автору на редагування матеріал "{{title}}"?',
  archiveTitle: 'Перемістити публікацію в архів?',
  archiveSuccess: 'Ваша стаття була успішно відправлена в архів',
  publishTitle: 'Опублікувати статтю?',
  publishSuccess: 'Ваша стаття була успішно опублікована',
  returnSuccess: 'Ваша стаття була успішно повернена автору',
  deleteTitle: 'Ви дійсно хочете видалити публікацію?',
  deleteSuccess: 'Ваша стаття була успішно видалена',
  changeViewsCountTitle: 'Змінити кількість переглядів?',
  changeViewsCountSuccess: 'Кількість переглядів успішно змінена',
  changeDateSuccess: 'Дату публікації змінено',
  scheduleDateSuccess: 'Дату публікації заплановано',
  schedulePublishTitle: 'Запланувати публікацію?',
  from: 'з',
  to: 'по',
  until: `до`,
  fetchError: 'Виникла помилка при завантаженні матеріалів',
  importantEmpty: `Зараз секція 'Важливе' порожня. Почніть додавати матеріали за допомогою меню знизу`,
  addToCarousel: 'Додати до каруселі',
  imagesDesktop: 'Зображення для компʼютера',
  imagesMobile: 'Зображення для телефона',
  imagesTablet: 'Зображення для планшета',
  review: 'Переглянути',
  sendForReview: 'Відправити на модерацію',
  sendForReviewTitle: 'Відправити публікацію на модерацію?',
  sendForReviewSuccess: 'Ваша стаття була успішно відправлена на модерацію',
  close: 'Закрити',
  selectedImportantMaterials: 'Зараз використовуються:',
  selectOption: `Оберіть об'єкт налаштування у меню`,
  resetTableFilters: `Скинути всі фільтри`,
  searchTitlePlaceholder: `Пошук по назві`,
  searchAuthorPlaceholder: `Пошук по автору`,
  dateOfCreation: `Дата створення`,
  dateOfEdition: `Дата редагування`,
  region: `Регіон`,
  city: `Місто`,
  fullName: `Ім'я та Прізвище`,
  removeFromCarousel: `Ви дійсно хочете видалити цей матеріал з Важливих?`,
  removeAuthor: `Ви насправді хочете видалити автора?`,
  removeAuthorAftermath: `Після підтвердження даної дії всі чернетки автора будуть видалені та автора не буде відображатися у "Список авторів"`,
  confirmRemoveAuthor: `ТAK`,
  discardRemoveAuthor: `Ні`,
  removeAuthorWarning: `Дозволяється видаляти тільки тих авторів, які не мають постів або всі пости мають статус "Чернетка"!`,
  authorsPerPageInfo: `Відображено від {{rangeStart}} до {{rangeEnd}} записів з {{totalElements}} доступних`,
  maxImportantPostCountReached: `Досягнуто максимум важливих постів`,
  createNewAuthor: `Створити нового автора`,
  showNotesAmount: `Відобразити <dropDown/> записів`,
};
